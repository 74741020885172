import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "transaction-issues"
    }}>{`Transaction Issues`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "long-time-character-minting"
    }}>{`Long time character minting`}</h2>
    <p>{`We apologize in advance that character minting might take long sometimes. Please be patient because Polygon network could be congested and takes 30 minutes - 1 hour for a transaction.`}</p>
    <p>{`While your transaction is pending. Please do not close the browser tab because your transaction may fail and you will not get your NFT character.`}</p>
    <p><img alt="transaction" src={require("./public/images/tab-1.png")} /></p>
    <p>{`For the user who has paid but do not get the character, please report us at `}<a parentName="p" {...{
        "href": "https://share.hsforms.com/16xae8YKwRJ6qA_0BPzc6nQ3tki0"
      }}>{`Report Form`}</a>{` `}</p>
    <p>{`In some cases, a transaction was confirmed but failed to mint the character, we will refund spent CLC to your wallet.`}</p>
    <p><img alt="transaction" src={require("./public/images/tab-2.png")} /></p>
    <p>{`Don't worry, we will answer and help all issues as soon as possible. And we apologize again for wasting your time.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "rpc-network"
    }}>{`RPC Network`}</h2>
    <p>{`We recommend using new unified RPC URL `}<a parentName="p" {...{
        "href": "https://polygon-rpc.com"
      }}>{`https://polygon-rpc.com`}</a>{` to avoid congestion on Polygon Mainnet.`}</p>
    <h3 {...{
      "id": "how-to-change-the-rpc-url-"
    }}>{`How to change the RPC URL ?`}</h3>
    <ol>
      <li parentName="ol">{`Click your Metamask profile account icon and enter `}<strong parentName="li">{`Settings`}</strong>{` menu.`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-1.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Networks`}</strong>{` and select the network named `}<strong parentName="li">{`Polygon Mainnet`}</strong>{` or the name that you created.`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-2.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Change your RPC URL to `}<a parentName="li" {...{
          "href": "https://polygon-rpc.com"
        }}>{`https://polygon-rpc.com`}</a>{` and save.`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      